<template>
  <div class="user-product pt20">
    <div v-if="!RedeemVisible">
      <ProductCard :value="productIconList" />
      <Order :value="product.orderList" />
      <el-row class="button-bar" v-if="buttonVisible">
        <el-button type="primary" @click="openRedeem()"
          >Redeeming Fund</el-button
        >
      </el-row>
    </div>
    <div v-else>
      <ProductCard :value="redeemIconList" />
      <Redeem :product="product" />
    </div>
  </div>
</template>

<script>
import Order from "./Order.vue";
import Redeem from "./Redeem.vue";
import ProductCard from "./ProductCard.vue";
import { userProductInfo } from "../../http/api";
import { Product, Order as Utility } from "./index";

export default {
  name: "UserProduct",
  components: { Order, ProductCard, Redeem },
  props: ["tab"],
  data() {
    return {
      util: Utility,
      product: {},
      productIconList: [],
      redeemIconList: [],
      buttonVisible: false,
      RedeemVisible: false,
    };
  },
  mounted() {
    this.queryUserProduct();
  },
  methods: {
    queryUserProduct() {
      const params = {
        // product_id: "384595120",
        product_id: this.tab.id,
      };
      userProductInfo(params).then((res) => {
        const product = res.data;
        this.product = product;
        this.buttonVisible = product.status === 2;

        this.productIconList = [
          {
            label: "Investment Amount",
            value: product.amount,
            // src: require("@/assets/images/amount.svg"),
            src: "images/amount.svg",
          },
          {
            label: "Qty",
            value: product.qty,
            src: "images/qty.svg",
          },
          {
            label: "Start Date",
            value: product.start,
            src: "images/calender.svg",
          },
          {
            label: "Est. Annual Return",
            value: product.yield + "%",
            src: "images/yield.svg",
          },
          {
            label: "Use $",
            value: product.gain,
            src: "images/gain.svg",
          },
          {
            label: "Expected Mature Date",
            value: product.end,
            src: "images/calender.svg",
          },
          {
            label: "Status",
            value: Product.fromStatus(product.status),
            src: "images/gain.svg",
          },
        ];

        this.redeemIconList = [
          {
            label: "Product Number",
            value: product.number,
            src: "images/yield.svg",
          },
          {
            label: "Product Name",
            value: product.product_name,
            src: "images/yield.svg",
          },
          {
            label: "Investment Amount",
            value: product.amount,
            src: "images/amount.svg",
          },
          {
            label: "Qty",
            value: product.qty,
            src: "images/qty.svg",
          },
          {
            label: "Qty Amount",
            value: product.qty_money,
            src: "images/qty.svg",
          },
        ];
      });
    },
    openRedeem() {
      const panel = { id: "", name: "Redeeming Fund", type: "Redeem" };
      this.$emit("addTab", panel);
      this.RedeemVisible = true;
    },

    processIconList() {},
  },
};
</script>

<style lang="scss">
.button-bar {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>