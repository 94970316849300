<template>
  <div class="order">
    <el-table :data="value" stripe class="order-list">
      <el-table-column prop="order_id" label="Order #">
        <template slot-scope="scope">
          <span
            :class="getCellClass(scope.row.status)"
            @click="showReceipt(scope.row)"
          >
            {{ scope.row.order_id }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Type">
        <template slot-scope="scope">
          {{ util.fromType(scope.row.type) }}
        </template>
      </el-table-column>
      <el-table-column prop="billing_time" label="Billing Date">
      </el-table-column>
      <el-table-column prop="amount" label="Amount">
        <template slot-scope="scope">
          {{ scope.row.amount + " AUD" }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status">
        <template slot-scope="scope">
          {{ util.fromStatus(scope.row.status) }}
        </template>
      </el-table-column>
    </el-table>

    <Receipt
      :orderId="selectOrderId"
      :visible="dialogVisible"
      @close="dialogVisible = false"
      v-if="dialogVisible"
    />
  </div>
</template>

<script>
import { Order as Utlity } from "./index";
import Receipt from "./Receipt.vue";

export default {
  name: "Order",
  components: { Receipt },
  props: ["value"],
  data() {
    return {
      util: Utlity,
      selectOrderId: "",
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    showReceipt(row) {
      if (row.status === 1) {
        this.selectOrderId = row.order_id;
        this.dialogVisible = true;
      }
    },
    getCellClass(status) {
      return status === 1 ? "link" : "";
    },
  },
};
</script>

<style lang="scss">
</style>