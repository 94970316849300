<template>
  <div class="main">
    <el-tabs class="tabview" v-model="activeTab" @tab-click="handleClick">
      <el-tab-pane label="My Products" name="product">
        <Product @addTab="adjustTab($event)" />
      </el-tab-pane>
      <el-tab-pane label="Account Settings" name="account">
        <UserAccount @addTab="adjustTab($event)" />
      </el-tab-pane>
      <!-- <el-tab-pane label="User Product" name="third">
        <UserProduct />
      </el-tab-pane>
      <el-tab-pane label="Billing" name="fourth">
        <Billing />
      </el-tab-pane> -->
      <el-tab-pane :label="thirdTab.name" name="third" v-if="thirdTabVisible">
        <component
          v-bind:is="thirdTab.content"
          :tab="thirdTab"
          @addTab="adjustTab($event)"
        >
        </component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Billing from "../components/account/Billing.vue";
import Product from "../components/account/Product.vue";
import UserProduct from "../components/account/UserProduct.vue";
import UserAccount from "../components/account/UserAccount.vue";

export default {
  name: "Account",
  components: { Billing, Product, UserAccount, UserProduct },
  data() {
    return {
      // activeName: "product",
      thirdTab: {},
      thirdTabVisible: false,
    };
  },
  created() {
    // this.$watch(
    //   () => this.$route.params,
    //   (toParams) => {
    //     // console.log("toParams=", toParams);
    //     if (toParams.id === "billing") {
    //       const tab = { name: "Billing", content: "Billing" };
    //       this.adjustTab(tab);
    //     } else {
    //       this.activeName = toParams.id;
    //     }
    //   }
    // );
  },
  computed: {
    activeTab: {
      get() {
        return this.$store.state.activeTab;
      },
      set(v) {
        this.$store.commit("updateTab", v);
      },
    },
  },
  methods: {
    // handleClick(tab, event)
    handleClick(tab) {
      // console.log(tab, event);
      if (["0", "1"].includes(tab.index)) {
        this.thirdTabVisible = false;
      }
      // this.$router.push(`/account/${tab.name}`);
    },

    // dynamic component can not render itself
    adjustTab(event) {
      // console.log(event);
      // for redeem, just update tabpanel name
      if (event.type === "Redeem") {
        this.thirdTab = { ...this.thirdTab, name: event.name };
        return;
      }

      // for others, update entire tabpanel
      this.thirdTab = event;
      this.thirdTabVisible = true;      
      this.$store.commit('updateTab', 'third');
    },
  },
};
</script>

<style lang="scss">
.tabview {
  width: var(--panel-width);
  margin: 30px auto;
}
</style>