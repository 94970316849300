<template>
  <div class="summary">
    <div class="item" v-for="item in value" :key="item.text">
      <img :src="item.src" />
      <div class="right">
        <div class="label">{{ item.label }}</div>
        <div class="value">{{ item.value }}</div>
      </div>
    </div>
    <!-- another handwriting of template -->
    <!-- <div class="redeem">
      <el-row>
        <el-col :span="8" class="item">
          <img src="images/amount.svg" />
          <div class="right">
            <div class="label">1</div>
            <div class="value">2</div>
          </div>
        </el-col>
        <el-col :span="8"></el-col>
        <el-col :span="8"></el-col>
      </el-row>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: ["value"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.summary {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  // border: 1px solid red;

  .item {
    flex: 0 0 33%;
    display: flex;
    // padding: 30px 0px;
    padding-bottom: 50px;

    img {
      width: 70px;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;

      .label {
        margin-bottom: 5px;
      }

      .value {
        font-family: "Din";
        font-size: 24px;
        color: var(--primary-color);
      }
    }
  }
}
</style>