<template>
  <div class="product">
    <el-row class="product-toolbar">
      <el-input
        placeholder="Search"
        v-model="searchWord"
        @keydown.enter.native="queryProductList()"
        clearable
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search hand"
          @click="queryProductList()"
        ></i>
      </el-input>
    </el-row>
    <el-table :data="productList" stripe style="width: 100%">
      <el-table-column prop="number" label="Number">
        <template slot-scope="scope">
          <!-- <router-link target="_blank" to="/announce">{{
            scope.row.number
          }}</router-link> -->
          <span class="link" @click="openAnnounce(scope.row.number)">
            {{ "#" + scope.row.number }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="product_name" label="Name"></el-table-column>
      <el-table-column prop="status" label="Status">
        <template slot-scope="scope">
          {{ util.fromStatus(scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="Amount"></el-table-column>
      <el-table-column prop="qty" label="Qty"></el-table-column>
      <el-table-column prop="yield" label="Est. Annual Return">
        <template slot-scope="scope">
          {{ scope.row.yield + "%" }}
        </template>
      </el-table-column>
      <el-table-column prop="gain" label="Use $">
        <template slot-scope="scope">
          <span
            :class="getGainClass(scope.row.status)"
            @click="openProduct(scope.row)"
          >
            {{ scope.row.gain }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { productList } from "../../http/api";
import { Product, PRODUCT_PAGE } from "./index";

export default {
  name: "Product",
  data() {
    return {
      util: Product,
      searchWord: "",
      productList: [],
    };
  },
  mounted() {
    this.queryProductList();
  },
  methods: {
    queryProductList() {
      const params = { search: this.searchWord };
      productList(params).then((res) => {
        this.productList = res.data;
      });
    },

    openProduct(row) {
      if (row.status >= 2 && row.status != 5) {
        const panel = {
          id: row.number,
          name: row.product_name,
          content: "UserProduct",
        };
        this.$emit("addTab", panel);
      }
    },

    openAnnounce(productId) {
      if (PRODUCT_PAGE.has(productId)) {
        const productPage = PRODUCT_PAGE.get(productId);
        // console.log(productId, productPage);
        this.$router.push(productPage);
      } else {
        this.$message.warning("Product page not found.");
      }
    },

    getGainClass(status) {
      return status >= 2 && status != 5 ? "link" : "";
    },
  },
};
</script>

<style lang="scss">
.product-toolbar {
  margin: 10px auto;
}
// .product .el-table th.el-table__cell {
//   color: #003d62;
//   background-color: #eeeeee;
// }
</style>