<template>
  <div>
    <el-card class="redeem">
      <el-row type="flex" justify="end">
        <el-input
          v-model="redeemQty"
          class="search-box"
          @input="calculate()"
          clearable
          ><template slot="prepend">Please Input Redeem Qty</template>
        </el-input>
      </el-row>
      <el-divider></el-divider>
      <div class="redeem-form">
        <div class="row">
          <div>Redeem Qty :</div>
          <div>{{ redeemQty }}</div>
        </div>
        <div class="row">
          <div>Redeem Amount :</div>
          <div>{{ redeemAmount }} AUD</div>
        </div>
        <div class="row">
          <div>Redeem Service Charge :</div>
          <div>{{ serviceCharge }}%</div>
        </div>
        <div class="row">
          <div>Redeem Service Amount :</div>
          <div>{{ serviceAmount }}</div>
        </div>
      </div>
      <el-row type="flex" justify="end" class="mt20">
        <el-button type="primary" @click="redeem()">Submit</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { redemingFunds } from "../../http/api";

export default {
  name: "Redeem",
  props: ["product"],
  data() {
    return {
      redeemQty: 0,
      redeemAmount: 0,
      serviceCharge: 0,
      serviceAmount: 0,
    };
  },
  mounted() {
    // redeem can only be raised before endDate
    if (!Number.isNaN(this.serviceCharge)) {
      const nowDate = new Date().getTime();
      const endDate = new Date(this.product.end + " 23:59:59").getTime();
      // console.log(nowDate, endDate);
      if (nowDate < endDate) {
        const ratio = Number.parseInt(this.product.service_charge) / 100;
        this.serviceCharge = ratio.toFixed(2);
      }
    }
  },
  methods: {
    redeem() {
      // if false, qtyNumber = NaN
      const qtyNumber = Number.parseInt(this.redeemQty);

      if (qtyNumber <= 0) {
        this.$message.error("Redeem Qty must be greater than 0");
        return;
      }

      const params = {
        product_id: this.product.number,
        qty_number: qtyNumber,
      };

      redemingFunds(params).then(() => {
        this.$message.success("Redeem Success");
      });
    },

    calculate() {
      const { qty, qty_money } = this.product;

      // filter, only number pass
      this.redeemQty = Number.parseInt(
        String(this.redeemQty).replace(/[^\d]/g)
      );

      if (this.redeemQty < 0 || Number.isNaN(this.redeemQty)) {
        this.redeemQty = 0;
      }

      if (this.redeemQty > qty) {
        // this.$message.error("Redeem Qty is greater than product Qty");
        this.redeemQty = qty;
        // return;
      }

      // total = 赎回份额*单价, service ammount = 服务费, redeem amount = 赎回金额
      const total = this.redeemQty * qty_money;
      this.serviceAmount = (total * this.serviceCharge).toFixed(2);
      this.redeemAmount = (total - this.serviceAmount).toFixed(2);
    },
    validate() {},
  },
};
</script>

<style lang="scss">
.redeem {
  .search-box {
    width: 300px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
}
</style>