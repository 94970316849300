<template>
  <div class="user-card">
    <el-card class="user-info" shadow="never">
      <div slot="header" class="clearfix">
        <span>Account</span>
        <el-button
          class="edit"
          type="text"
          @click="editable = true"
          v-if="!editable"
        >
          Edit
        </el-button>
      </div>
      <div class="row">
        <div class="item">
          <div class="label">Full Name</div>
          <div class="value">
            <span v-if="editable">
              <el-input v-model="user.name"></el-input>
            </span>
            <span v-else>
              {{ user.name }}
            </span>
          </div>
        </div>
        <div class="item">
          <div class="label">Phone</div>
          <div class="value">{{ user.phone }}</div>
        </div>
        <div class="item">
          <div class="label">Address</div>
          <div class="value">
            <span v-if="editable">
              <el-input v-model="user.address"></el-input>
            </span>
            <span v-else>{{ user.address }} </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <div class="label">Email</div>
          <div class="value">
            <span v-if="editable">
              <el-input v-model="user.email"></el-input>
            </span>
            <span v-else>
              {{ user.email }}
            </span>
          </div>
        </div>
        <div class="item">
          <div class="label">Default Cuttency</div>
          <div class="value">AUD</div>
        </div>
        <div class="item">
          <div class="label">Customer #</div>
          <div class="value">{{ user.customer_id }}</div>
        </div>
      </div>
      <el-row
        class="button-bar"
        v-if="editable"
        type="flex"
        justify="flex-start"
      >
        <el-button type="primary" @click="updateUserInfo()">Save</el-button>
        <el-button @click="editable = false">Cancel</el-button>
      </el-row>
    </el-card>

    <div class="menu">
      <div class="row">
        <el-card class="item" shadow="never">
          <a @click="openBilling()">Billing</a>
        </el-card>
        <el-card class="item" shadow="never">
          <router-link to="/contact">Contact Us</router-link>
        </el-card>
        <el-card class="item" shadow="never"><span>&nbsp;</span></el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo, userInfoUpdate } from "../../http/api";

export default {
  name: "UserAccount",
  data() {
    return {
      editable: false,
      user: {
        name: "",
        phone: "",
        address: "",
        email: "",
        // cuttency: "",
        customer_id: "",
      },
    };
  },

  mounted() {
    this.queryUserInfo();
  },

  methods: {
    queryUserInfo() {
      userInfo().then((res) => {
        this.user = res.data;
      });
    },
    updateUserInfo() {
      const params = {
        name: this.user.name,
        email: this.user.email,
        address: this.user.address,
      };
      userInfoUpdate(params).then(() => {
        this.editable = false;
        this.$message.success("Update success");
        userInfo().then((res) => {
          const user = res.data;
          localStorage.setItem("user", JSON.stringify(user));
        });
      });
    },
    openBilling() {
      const panel = { name: "Billing", content: "Billing" };
      this.$emit("addTab", panel);
    },
  },
};
</script>

<style lang="scss">
// .clearfix:before,
// .clearfix:after {
//   display: table;
//   content: "";
// }
// .clearfix:after {
//   clear: both;
// }

.user-card {
  width: 100%;
}

.user-info {
  .edit {
    float: right;
    padding: 3px 0;
  }

  .row {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
  }

  .item {
    flex: 0 0 33%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .label {
    margin-bottom: 5px;
  }

  .value {
    color: var(--primary-color);
  }
}

.menu {
  .row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .item {
    flex: 0 0 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    cursor: pointer;
    span {
      color: var(--primary-color);
    }
  }

  .item:nth-child(2) {
    margin: 0px 10px;
  }
}
</style>