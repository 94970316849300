<template>
  <div class="receipt">
    <el-dialog
      title=""
      :visible.sync="visible"
      width="90%"
      :before-close="handleClose"
    >
      <div class="receipt-info">
        <div class="row">
          <div class="item">
            <div class="label">Receipt</div>
            <div class="value">{{ receipt.order_id }}</div>
          </div>
          <div class="item">
            <div class="label"></div>
            <div class="value"></div>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <div class="label">Date</div>
            <div class="value">{{ receipt.billing_time }}</div>
          </div>
          <div class="item">
            <div class="label">Customer #</div>
            <div class="value">{{ receipt.customer_id }}</div>
          </div>
        </div>
      </div>
      <!-- bill -->
      <div class="bill-info">
        <div class="column">
          <div class="label">Bill to</div>
          <div class="" v-html="formatAddress(receipt.address)">
            <!-- <p>Unit 3,</p>
            <p>10 Elizabeth Street,</p>
            <p>Clayton, Victoria,</p>
            <p>Australia, 3168</p>
            <p>63 2224203</p> -->
          </div>
        </div>
        <div class="column">
          <div class="label">PAYMENT</div>
          <div class="payment">
            <div class="row">
              <div class="item">From</div>
              <div class="item">BANK {{ receipt.pay_from }}</div>
            </div>
            <div class="row">
              <div class="item">To</div>
              <div class="item">BANK {{ receipt.pay_to }}</div>
            </div>
            <div class="row">
              <div class="item">Amount</div>
              <div class="item" v-if="receipt.type == 0">{{ receipt.amount }} AUD</div>
              <div class="item" v-else-if="receipt.type == 1">{{ receipt.amount }} AUD</div>
              <div class="item" v-else-if="receipt.type == 2">{{ receipt.gain }} AUD</div>
            </div>
          </div>
        </div>
      </div>
      <!-- table -->
      <el-table :data="[receipt]" stripe style="width: 100%" class="order-grid">
        <el-table-column prop="product_id" label="Number"></el-table-column>
        <el-table-column
          prop="product_name"
          label="Name"
          width="160px"
        ></el-table-column>
        <el-table-column prop="amount" label="Amount"></el-table-column>
        <el-table-column prop="qty" label="Qty"></el-table-column>

        <el-table-column
          prop="yield"
          label="Amplified Field"
          v-if="receipt.type == 2"
        >
          <template>
            {{ receipt.yield + "%" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="gain"
          label="Cunulative Gain"
          v-if="receipt.type == 2"
        ></el-table-column>
        <el-table-column
          prop="service_charge"
          label="Service Charge"
          v-if="receipt.type == 1"
        >
          <template>
            {{ receipt.service_charge + "%" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="service_money"
          label="Service Money"
          v-if="receipt.type == 1"
        ></el-table-column>
      </el-table>
      <!-- button -->
      <el-row class="button-print" v-if="buttonVisible">
        <el-button type="primary" @click="download(receipt.bill_url)">
          <img src="images/printer.svg" />Print to PDF
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { userOrderInfo } from "../../http/api";

export default {
  name: "Receipt",
  props: ["orderId", "visible"],
  data() {
    return {
      receipt: { bill_url: null },
      buttonVisible: false,
    };
  },
  mounted() {
    this.queryReceipt();
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },

    queryReceipt() {
      const params = {
        // order_id: "112323",
        order_id: this.orderId,
      };
      userOrderInfo(params).then((res) => {
        this.receipt = res.data;
        this.buttonVisible = this.receipt.bill_url !== null;
      });
    },

    formatAddress(address = "") {
      // console.log(address);
      let wordArr = address.split(",");
      let words = "";
      if (wordArr.length > 4) {
        words = "<p>";
        words +=
          wordArr[0] +
          ",</p><p>" +
          wordArr[1] +
          ",</p><p>" +
          wordArr[2] +
          ",</p><p>" +
          wordArr.splice(3) +
          "<p>";
      } else {
        words = address.replace(/,/g, ",</p><p>");
        words = "<p>" + words + "<p>";
      }
      words += this.receipt.phone;
      // console.log(words);
      return words;
    },

    // download pdf
    download(data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "receipt.pdf");
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="scss">
.receipt-info {
  .row {
    display: flex;
    justify-content: space-between;
  }
  .row:nth-child(1) {
    border-bottom: 1px solid silver;
  }
  .item {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .label {
    color: var(--primary-color);
    margin-bottom: 5px;
  }

  .value {
    height: 30px;
  }
}

.bill-info {
  background-color: #f2f5f8;
  padding: 30px;
  display: flex;

  .label {
    color: var(--primary-color);
    padding: 10px 0px;
  }
  .column:nth-child(1) {
    flex-basis: 40%;
    padding-right: 30px;
  }
  .column:nth-child(2) {
    flex-basis: 70%;
  }

  .payment {
    .row {
      display: flex;
      justify-content: space-between;
    }
    .row:nth-child(2) {
      border-bottom: 1px solid silver;
    }
    .row:nth-child(3) {
      border-bottom: 1px solid silver;
      padding: 20px 0px;
    }
    .row .item {
      padding: 10px 0px;
    }
  }
}

.order-grid {
  margin-top: 50px;
}

.button-print {
  margin-top: 30px;
  img {
    width: 30px;
    padding-right: 10px;
  }
}
</style>