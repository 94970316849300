<template>
  <div class="billing">
    <Order :value="orderList" />
  </div>
</template>

<script>
import { orderList } from "../../http/api";
import Order from "./Order.vue";

export default {
  name: "Billing",
  components: { Order },

  data() {
    return {
      orderList: [],
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      orderList().then((res) => {
        this.orderList = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.billing {
}
</style>